@forward './main.scss';
@forward './module/media-centre';
@use './config/' as *;
.load-bg-pattern {
    .waf-media-centre {
        .waf-head {
            &::after {
                @include bgImg('cssimages/pattern/details-bg-mob.png');
            }
        }
    }
}
@include mq(col-tablet) {
    .load-bg-pattern {
        .waf-media-centre {
            .waf-head {
                &::after {
                    @include bgImg('cssimages/pattern/details-bg-web.png', center, cover);
                }
            }
        }
    }
}