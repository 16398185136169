@use "../config/" as *;
.waf-media-centre {
    @extend %page-head-blue-bg;
    isolation: isolate;
    @extend %relative;
    &::before {
        @include position(20%);
    }
    .head-wrap {
        .title {
            @extend %title-40;
        }
    }
    .waf {
        &-head {
            border-radius: unset;
            isolation: isolate;
            @extend %relative;
            &::after {
                content: '';
                rotate: 180deg;
                pointer-events: none;
                background-size: cover;
                @include position-combo(inset);
                @extend %w-100;
                @extend %h-100;
                @extend %zindex-pattern;
            }
            &::before {
                @extend %zindex-media-vector;
            }
        }
        &-body {
            @extend %bg-transparent;
        }
    }
    .card {
        &-wrapper {
            @extend %flex-column;
            @extend %gap-4;
        }
    }
    .social {
        &-links-wrapper {
            order: -1;
        }
        &-head {
            @extend %font-0;
        }
        &-list {
            @extend %flex-sa-c;
            @extend %gap-6;
        }
        &-link-list {
            @include bg(neutral-0, 3);
            @extend %rounded-radius;
            @extend %p-4;
            @extend %mb-6;
        }
        &-text {
            @extend %font-0;
            &::before {
                font-size: 2.4rem;
            }
        }
    }
    .name {
        @extend %body-mb;
        @extend %neutral-100;
    }
    .enquiry,
    .email {
        @extend %neutral-70;
    }
    .enquiry {
        @extend %body-mb;
    }
    .email {
        @extend %body-m;
    }
}
.waf-media-centre,
.waf-media-resource {
    &::before {
        pointer-events: none;
    }
    &.waf-component {
        @extend %p-0;
    }
    .layout-wrapper {
        max-width: unset;
    }
    .card {
        &-item {
            @extend %p-6-4;
            @extend %bg-neutral-0;
            @extend %border-radius-m;
        }
        &-title,
        &-label {
            @extend %title-40;
        }
        &-description {
            @extend %my-8;
            @extend %body-l;
        }
        &-link {
            @extend %neutral-100;
            @extend %body-mb;
            @extend %flex-n-c;
        }
        &-action {
            @extend %mb-8;
        }
        &-details {
            &:not(:last-child) {
                @extend %mb-4;
            }
            > span {
                @extend %d-block;
            }
            + .card-link {
                @extend %d-block;
            }
        }
    }
    .btn {
        width: max-content;
        &-fill {
            @extend %btn-fill;
            @extend %mb-6;
        }
        &-link {
            @extend %btn-default;
            @extend %pl-0;
        }
        &-outline {
            @extend %btn-outline-icon;
            @extend %gap-2;
            &::after {
                @include icon(arrow-small-right, 24);
            }
        }
    }
}
.waf-media-resource {
    &::before {
        height: calc(100% + var(--space-4));
        @include position(var(--space-4-neg), null, null, 0);
    }
    .resource {
        &-section {
            @extend %mb-4;
        }
        &-list {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - var(--space-6));
            @extend %gap-4;
            @extend %x-auto;
        }
        &-head {
            @extend %title-50;
            @extend %m-y-12-4;
        }
        &-item {
            grid-area: 2;
            @include border(1, neutral-20);
            @extend %border-radius-m;
            @extend %p-6-4;
            @extend %flex-column;
            @extend %gap-4;
        }
        &-title {
            @include title-with-icon(null, document, 24);
            @include truncate-text(2);
            @extend %mb-0;
            @extend %flex-column;
            @extend %gap-4;
        }
        &-action {
            .btn-link {
                @extend %gap-2;
            }
        }
        &-links-section {
            @extend %neutral-5;
            .card-item {
                @extend %bg-neutral-60;
            }
        }
        &-description {
            flex-grow: 1;
        }
    }
    .link {
        @extend %neutral-5;
        @extend %w-100;
        &-item {
            border: 0.1rem solid var(--neutral-5);
            @include bg(neutral-5, 3);
            @extend %border-radius-s;
            @extend %title-50;
            @extend %w-100;
            @extend %p-6-4;
            @extend %flex-sb-c;
            &::after {
                @include icon(arrow-small-right, 24);
            }
        }
        &s {
            &-section {
                @extend %flex-column;
                @extend %gap-4;
            }
        }
    }
    .more {
        &-section {
            @extend %mt-12;
            @extend %flex-column;
            @extend %gap-4;
        }
        &-item {
            @extend %border-radius-m;
            @extend %hidden;
            @extend %font-0;
        }
    }
}
.waf-listing.waf-overlay-list {
    &::before {
        @include position-combo(tl);
    }
    .layout-wrapper {
        max-width: var(--lg-container-max-width);
        @extend %bg-neutral-0;
        @extend %p-6-4;
        @extend %border-radius-m;
    }
    .article-list {
        grid-auto-flow: column;
        grid-auto-columns: calc(100% - var(--space-6));
        grid-template-columns: unset;
        overflow-x: auto;
    }
}
.waf-media-centre,
.waf-media-resource,
.waf-listing {
    &::before {
        content: '';
        @extend %bg-neutral-100;
        @extend %w-100;
        @extend %h-100;
        @extend %zindex-pattern;
    }
    .waf-body {
        max-width: var(--lg-container-max-width);
        width: 100%;
        margin-inline: auto;
    }
}
@include mq(col-tablet) {
    .waf-media-centre {
        .waf-head {
            &::after {
                rotate: unset;
            }
        }
        .card {
            &-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 8rem auto;
                .card-item {
                    grid-area: 1/1/3/2;
                }
            }
        }
        .social {
            &-links-wrapper {
                order: unset;
            }
        }
        .media {
            &-section {
                grid-area: 2/2;
            }
        }
    }
    .waf-listing.waf-overlay-list {
        .article-list {
            grid-auto-flow: unset;
            grid-auto-columns: unset;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            overflow-x: unset;
        }
    }
    .waf-media-resource {
        .resource {
            &-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: var(--space-4);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing.waf-overlay-list {
        .article-list {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    .waf-media-centre {
        .waf {
            &-head {
                min-height: 65rem;
            }
            &-body {
                margin-top: -21rem;
            }
        }
        .card {
            &-wrapper {
                grid-template-rows: 14rem auto;
            }
        }
        .social {
            &-head {
                font-size: 2.4rem;
                color: var(--neutral-5);
            }
            &-link-list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: var(--border-radius-l);
                padding: 4.8rem 4rem;
            }
        }
    }
    .waf-media-centre,
    .waf-media-resource {
        .card {
            &-item {
                padding: var(--space-12);
            }
        }
    }
    .waf-listing.waf-overlay-list {
        .layout-wrapper {
            padding: 4.8rem;
        }
    }
    .waf-media-resource {
        .resource {
            &-list {
                overflow-x: hidden;
                grid-auto-columns: unset;
                grid-auto-flow: unset;
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-media-resource {
        .resource {
            &-wrapper {
                grid-template-columns: calc(100% - 59.4rem) 61rem;
            }
        }
    }
}